/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check-inactive': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<rect pid="0" width="14" height="14" x=".5" y=".5" _fill="none" _stroke="#1D1D44" rx="1"/>'
  }
})
